import React from 'react'
import { Helmet } from 'react-helmet'
// Screens
import Landing from './screens/Landing.jsx'
import WhatsAppWidget from 'react-whatsapp-widget'
import 'react-whatsapp-widget/dist/index.css'

export default function App () {
  return (
    <>
      <Helmet>
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
        <link
          href='https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap'
          rel='stylesheet'
        />
      </Helmet>
      <Landing />

      <div style={{ position: 'absolute', zIndex: '90000' }}>
        <WhatsAppWidget phoneNumber='+254714730819' />
      </div>
    </>
  )
}
