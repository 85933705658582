import React from "react";
import styled from "styled-components";
// Components
import StepContainer from "../Elements/StepContainer";
import TestimonialSlider from "../Elements/TestimonialSlider";

export default function Steps() {
  return (
    <Wrapper id="steps">
      <div className="bgWhite"  style={{paddingBottom: '40px'}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">How to get your paper done.</h1>
            <p className="font13">
             In 4 simple steps
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
              <StepContainer
                title="Submit proposal"
                text="Provide a description of paper you need."
              />
            </div>
            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
              <StepContainer
                title="Get a tutor"
                text="Get a tutor to work on your paper "
              />
            </div>
            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
              <StepContainer
                title="Progress updates"
                text="Get updates on how the draft is comming up"
              />
            </div>
            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
              <StepContainer
                title="Get your paper"
                text="Receive your paper via email or whatsapp"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bgOffWhite" style={{padding: '20px 0 40px 0'}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">What They Say?</h1>
            <p className="font13">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  padding-top: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;